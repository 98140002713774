import React from "react"
import { Link, graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"


import UtilityStoryitem from '../components/utilityStoryitem.js'

/*import sanityClient from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'
// Get a pre-configured url-builder from your sanity client
const builder = imageUrlBuilder(sanityClient)

// Then we like to make a simple function like this that gives the
// builder an image and returns the builder for you to specify additional
// parameters:
function urlFor(source) {
  return builder.image(source)
}*/

// Conditional syntax in JSX
// https://stackoverflow.com/questions/53508119/what-is-the-syntax-for-a-simple-if-conditional-in-gatsby-js


export const query= graphql`
query {
    popOffer: allSanityOffer(limit:12, filter:{isDeleted: {ne: true}}, sort: {fields: popularity_rank, order: DESC}){
        edges {
          node {
            gkid {
              current
            }
            slug {
              current
            }
            title
            description {
              list
            }
            value
            image {
              ...Image
            }
          }
        }
      }
    
     newOffer: allSanityOffer(limit:7,filter:{isDeleted: {ne: true}}, sort: {fields: published, order: DESC}){
        edges {
          node {
            gkid {
              current
            }
            slug {
              current
            }
            title
            description {
              list
            }
            value
            image {
              ...Image
            }
          }
        }
      }
    
    bgImage:  file(relativePath: { eq: "home-01-1920x570.jpg" }) {
      childImageSharp {
        fixed(width: 1920, height: 570) {
          src
        }
      }
    }
    
    h1Image:  file(relativePath: { eq: "banner-arrow-184x147.png" }) {
      childImageSharp {
        fixed(width: 184, height: 147) {
          src
        }
      }
    }

    img1: file(relativePath: { eq: "page1_pic2-270x271.jpg" }) {
      childImageSharp {
        fixed(width: 270, height: 271) {
          src
        }
      }
    }
}
`

const PopularPage = ({data}) => {


  return (
    <Layout>
      <SEO title="Populære gratisting | Gratis.no"
           description={"En liste med de mest populære gratistingene akkurat nå."}
           image={data.bgImage.childImageSharp.fixed}
      />

      <div>
        {/* Få varsel
        <section
          className="section novi-bg novi-bg-img section-xl section-banner-classic bg-primary text-center text-lg-left"
          style={{backgroundImage: 'url('+data.bgImage.childImageSharp.fixed.src+')'}}>
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-9">
                <div className="banner-classic">
                  <h1>Få varsel om <span className="d-block font-weight-bold">nye gratisting!</span>
                    <img className="banner-classic-figure" src={data.h1Image.childImageSharp.fixed.src} alt="" width={184} height={73}/>
                  </h1>
                  <a className="button button-icon button-icon-right button-black button-lg" href="#"><span className="icon novi-icon fa fa-chevron-right"/>Trykk her</a>
                </div>
              </div>
            </div>
          </div>
        </section>*/}

        {/* Populære gratisting */}
        <section className="section novi-bg novi-bg-img section-lg bg-white section-top-shadow">
          <div className="container">
            <h2 style={{marginBottom:'80px'}} className="text-center text-sm-left">Populære <span className="h2-style">gratisting</span></h2>
            <div className="row row-50">

              {data.popOffer.edges.map(({ node: offer }) => (
                <div key={offer.gkid.current} className="col-lg-3 col-sm-6">
                  <UtilityStoryitem slug={offer.slug.current} headline={offer.title} image={offer.image} value={offer.value}/>
                </div>
              ))}

            </div>
            <div className="mt-xl-40 mt-50 text-center text-sm-right"><Link
              className="button button-icon button-icon-right button-black" to="/gratisting-med-hoy-verdi/"><span
              className="icon novi-icon fa fa-chevron-right"/>Vis verdifulle</Link></div>
          </div>
        </section>

      </div>
    </Layout>
  )
}

export default PopularPage


